import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import loadingAnimation from '../assets/json/loadingAnimation.json';

function Keypad({ onKeyPress }) {

    const [inputValue, setInputValue] = useState('');
    const [caratValue, setCaratValue] = useState('');
    const [buttonOff, setButtonOff] = useState(false);
    const [goldPrice, setGoldPrice] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');
  

    useEffect(() => {
        const fetchGoldPrice = () => {
            axios.get("https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetPrezzoOroWeb&np=0", {}, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(response => {
                const [prezzo] = response.data.split('#');
                setGoldPrice(prezzo);
            })
            .catch(error => {
                console.error('Failed to fetch gold price:', error);
            });
        };
    
        fetchGoldPrice();

        return () => {
            // Funzione di cleanup
        };
        
    }, []);
    

    const handleClick = (value) => {
        if(value === '='){     

            if(inputValue === ''){
                alert('Si prega di inserire il peso dell\'oro.');
                return;
            }
            if(caratValue === ''){
                alert('Si prega di inserire la caratura dell\'oro.');
                return;
            }
            setLoading(true);
            setButtonOff(false);

            const allOperation = inputValue + caratValue;
            const [weightStr, purityStr] = allOperation.split('x').map(str => str.trim());
            const weight = weightStr.replace('g', '');
            const number = parseFloat(weight.replace(',', '.'));
            const purity = parseFloat(purityStr.replace('k', '')) / 24; 
            const goldPricePerGram = goldPrice;
            const realPriceGold = (number * purity) * goldPricePerGram;
            const roundedNumber = realPriceGold.toFixed(2); 

            setInputValue('');
            setCaratValue('');


            setTimeout(() => {
                setLoading(false);
                setResult(roundedNumber + ' €');
            },1500)
        
            return;
        }
        if (value === 'AC') {
            setInputValue('');
            setCaratValue('');
            setButtonOff(false);
            setResult('');
            return;
        }
        if(value === 'Backspace'){
            setResult('');
            if(caratValue !== ''){
                setCaratValue('');
                const grams = inputValue.replace('g x', '').trim();
                setInputValue(grams + 'g');
                setButtonOff(false);
                return;
            }
            const grams = inputValue.replace('g', '').toString();
            if(grams.length > 1){
                setInputValue(grams.slice(0, -1)+'g');
                return;
            }
            if(grams.length <= 1){
                setInputValue(0 +'g');
                return;
            }
            
        }

        if (value.match(/^\d+k$/)) {
            if(result !== ''){
                setResult('');
                return;
            }
            if(inputValue === ''){
                alert('Devi inserire almeno un valore!');
                return;
            }
            if(caratValue.match(/^\d+k$/)){
                setCaratValue(value);
                return;
            }
            setResult('');
            setCaratValue(value);
            const grams = inputValue.replace('g', '');
            setInputValue(grams + 'g' + ' x ');
            setButtonOff(true);

            return;
        } 
        if (inputValue.includes(',')) {    
            const newValue = inputValue.replace('g', '');
            setInputValue(newValue + value + 'g');
            return;
        }
        if(inputValue.match(/^\d+g$/) && !buttonOff) {
            if(inputValue === '0g'){
                setInputValue(value + 'g');
                return;
            }
            const grams = inputValue.replace('g', '');
            setInputValue(grams + value + 'g');
            return;
        }
        if(!buttonOff){
            if (value === ',') {
                setResult('');
                setInputValue('');
                setCaratValue('');    
                return;
            }        
            setResult('');
            setInputValue(inputValue + value + 'g');
        }

    };


  return (
    <div className="keypad mb-20 px-2 text-slate-50">
      <div className="row px-8 md:px-24 mb-2">
        {loading ? (
            <div className='border borde-slate-50 rounded-lg bg-transparent text-slate-50 font-semibold w-full h-28 text-end'>
                <Lottie 
                    options={{
                        animationData: loadingAnimation,
                        loop: true,
                        autoplay: true
                    }}
                    height={100}
                    width={100}
                />
            </div>
        ) : (
            <input
                className='border borde-slate-50 rounded-lg bg-neutral-700 text-slate-50 font-semibold w-full h-28 bg-transparent text-3xl text-end pe-10'
                value={result + inputValue + caratValue}
                readOnly
            />
        )}
      </div>
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-md w-1/5 h-20' onClick={() => handleClick('AC')}>AC</button>
        <button className='border rounded-full m-1 font-semibold text-md w-2/5 h-20 uppercase' onClick={() => handleClick('Backspace')}>Backspace</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '24k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('24k')}>24k</button>
      </div>
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('1')}>1</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('2')}>2</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('3')}>3</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '22k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('22k')}>22k</button>
      </div>
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('4')}>4</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('5')}>5</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('6')}>6</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '18k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('18k')}>18k</button>
      </div>
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('7')}>7</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('8')}>8</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick('9')}>9</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '14k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('14k')}>14k</button>
      </div>
      {/* Operator buttons */}
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-xl w-2/5 h-20' onClick={() => handleClick('0')}>0</button>
        <button className='border rounded-full m-1 font-semibold text-xl w-1/5 h-20' onClick={() => handleClick(',')}>,</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '12k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('12k')}>12k</button>
      </div>
      <div className="row">
        <button className='border rounded-full m-1 font-semibold text-md w-3/5 h-20 uppercase bg-zinc-200 text-black cursor-pointer' onClick={() => handleClick('=')}>invia</button>
        <button className={`border rounded-full m-1 font-semibold text-xl w-1/5 h-20 ${caratValue === '9k' ? 'bg-slate-50 text-black' : 'text-animate-gold'}`} onClick={() => handleClick('9k')}>9k</button>
      </div>
    </div>
  );
}

export default Keypad;
