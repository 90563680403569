import React, { useState } from 'react';
import GoldChart from '../components/GoldPrice'; 
import FixingPrice from '../components/FixingGoldPrice';
import SpotGoldPrice from '../components/SpotGoldPrice';
import SpotGoldPriceWidget from '../components/SpotGoldPriceWidget';
import Navbar from "../components/Navbar";
// import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import imageShare from '../assets/logo/sherelogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo/logo_icon.png';

function HomePage() {

  const [showInfo, setShowInfo] = useState(false);
  const [modalFade, setModalFade] = useState('fadeIn');

  const handleOpenFixingPopup = () => {
    setShowInfo(true);
    setModalFade('fadeIn');
  }

  const handleCloseFixingPopup = () => {
    setModalFade('fadeOut');
    setTimeout(() => {
      setShowInfo(false);
    }, 1500);
  };

  return (
    <>
    <Helmet>
      <title>Prezzo dell'Oro: Fixing e Spot | prezzo-oro.com</title>
      <meta name="description" content="Trova i prezzi dell'oro aggiornati, inclusi il fixing e lo spot price, su prezzo-oro.com. Conversioni di valuta, grafico storico e altre informazioni sul prezzo dell'oro." />
      <meta name="keywords" content="prezzo oro, oro, prezzi oro fixing, spot price oro, conversione oro, grafico storico oro" />
      <meta name="author" content="prezzo-oro.com" />
      <meta property="og:title" content="Prezzo dell'Oro: Fixing e Spot | prezzo-oro.com" />
      <meta property="og:description" content="Trova i prezzi dell'oro aggiornati, inclusi il fixing e lo spot price, su prezzo-oro.com. Conversioni di valuta, grafico storico e altre informazioni sul prezzo dell'oro." />
      <meta property="og:image" content={imageShare} />
      <meta property="og:url" content="https://prezzo-oro.com" />
      <link rel="canonical" href="https://prezzo-oro.com" />
      <meta property="og:type" content="website" />
      <meta property="article:published_time" content="2024-04-20T10:30:00" />
      <meta property="article:author" content="prezzo-oro.com" />
      <meta property="article:section" content="Prezzo dell'Oro" />
      <meta property="article:tag" content="prezzo oro, oro, prezzi oro fixing, spot price oro, conversione oro, grafico storico oro" />
      <meta name="google-adsense-account" content="ca-pub-7812738073531507"/>
    </Helmet>
      <Navbar/>
      <div className='flex justify-center my-10'>
        <div className='grid grid-cols-6 gap-4'>
          <div className='col-span-6 md:col-span-3 flex justify-center'>
            <FixingPrice/>
          </div>
          <div className='col-span-6 mt-10 md:mt-0 md:col-span-3 flex justify-center'>
            <SpotGoldPriceWidget/>
          </div>
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='w-full md:w-1/2 px-2'>
          <h2 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold mb-5'>prezzo oro in tempo reale</h2>  
            <SpotGoldPrice/>
        </div>
      </div>
      <div className='flex justify-center mt-20'>
        <div className='w-full md:w-1/2'>
          <h2 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold mb-5'>Prezzo Oro Usato</h2> 
          <p className="text-base text-slate-200 mb-8 px-5 md:px-40">
              Calcola il valore dell'oro in base al prezzo corrente dell'oro sul mercato.
          </p> 
          <Link to='/calcolatrice-oro/' className="hover:text-slate-200 border rounded px-4 py-3 hover:bg-transparent bg-slate-200 text-black transition duration-300">Calcola il Valore del Tuo Oro Usato</Link>
        </div>
      </div>
      <div className='flex justify-center relative pt-20 pb-10'>
        <div className='w-full md:w-1/2 px-2'>
          <h2 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold mb-5'>Prezzo medio storico</h2>  
          <GoldChart/>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <h1 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold'>Prezzo Oro:<br/> Trova il Valore del tuo Oro</h1>     
      </div>
      <div className="flex items-center justify-center mb-16">
        <p className="text-neutral-300 text-lg mb-8 md:w-1/2 px-2">
        PREZZO-ORO.COM nasce per fornire informazioni aggiornate sui prezzi dell'oro, offrire valutazioni accurate per il tuo oro usato e tenerti informato sulle ultime notizie del settore. Siamo qui per aiutarti a comprendere il valore del tuo oro e a fornirti le informazioni necessarie per prendere decisioni informate sul suo acquisto, vendita o investimento. Esplora il nostro sito per scoprire tutto ciò che c'è da sapere sul prezzo dell'oro e molto altro ancora!
        </p>   
      </div>
      {showInfo && (
        <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center text-center glassmorphism ${modalFade}`}>
          <div className={`bg-transparent p-8 relative w-auto md:w-1/3`}>
            <span className='absolute top-0 right-0 cursor-pointer p-3' onClick={handleCloseFixingPopup}>
              <FontAwesomeIcon
                icon={faX}
                className="text-slate-50 opacity-75"
              />
            </span>
            <p className={`text-slate-50 text-justify font-semibold text-xl mb-6 mt-10`}>Utilizza il nostro calcolatore per ottenere il prezzo dell'oro usato in tempo reale con 3 semplici passaggi:</p>
            <ul className='text-start text-slate-50'>
              <li className='mb-3'><b>Step 1: </b> Seleziona dalla tastiera numerica la grammatura dell'oro che desideri convertire in euro.</li>
              <li className='mb-3'><b>Step 2: </b> Seleziona la caratura dell'oro tra le opzioni disponibili.</li>
              <li className='mb-3'><b>Step 3: </b> Premi il tasto "Invio" per ricevere istantaneamente il prezzo in euro per grammo dell'oro usato.</li>
            </ul>
          </div>
        </div>
      )}
        <footer className="text-center my-8">
          <Link to="/" className="text-neutral-100 flex items-center justify-center">
            <img src={logo} alt="Logo Prezzo Oro" className="mr-2 h-8 w-8" />
            Prezzo-Oro.com
          </Link>
        </footer>
    </>
  );
}

export default HomePage;
