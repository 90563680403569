import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import egypt from '../assets/prezzoOro/egypt.png';
import goldrush from '../assets/prezzoOro/goldrush.png';
import pepita from '../assets/prezzoOro/pepita.png';
import logo from '../assets/logo/logo_icon.png';
import { Link } from 'react-router-dom';

function Storia() {
  return (
    <>
      <Helmet>
        <title>Storia dell'oro: dalle origini ai giorni nostri | prezzo-oro.com</title>
        <meta name="description" content="Scopri la storia dell'oro, dalle sue antiche origini ai tempi moderni. Approfondisci le sue proprietà, il suo ruolo nella società e le prospettive future." />
        <meta name="keywords" content="storia dell'oro, oro, metalli preziosi, antichità, medioevo, epoca moderna, gioielleria, investimenti" />
        <meta name="author" content="PrezzoOro.com" />
        <meta property="og:title" content="Storia dell'oro: dalle origini ai giorni nostri | PrezzoOro.com" />
        <meta property="og:description" content="Scopri la storia dell'oro, dalle sue antiche origini ai tempi moderni. Approfondisci le sue proprietà, il suo ruolo nella società e le prospettive future." />
        <meta property="og:image" content={pepita} />
        <meta property="og:url" content="https://prezzo-oro.com/storia-del-oro" />
        <link rel="canonical" href="https://prezzo-oro.com/storia-del-oro" />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content="2024-04-20T10:30:00" />
        <meta property="article:author" content="prezzo-oro.com" />
        <meta property="article:section" content="Storia dell'oro" />
        <meta property="article:tag" content="storia dell'oro, metalli preziosi, antichità, medioevo, epoca moderna, gioielleria, investimenti" />
        <meta name="google-adsense-account" content="ca-pub-7812738073531507"/>
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-static-gold text-center mb-4">Storia dell'oro: dalle origini ai giorni nostri</h1>
          <p className="text-neutral-200 text-lg mb-8">
            L'oro ha affascinato l'umanità fin dai tempi antichi, con una storia che si estende per millenni. Scopriamo insieme le origini di questo metallo prezioso e il suo significato attraverso le epoche.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Antichità</h2>
          <p className="mb-8 text-neutral-400">
            Le prime tracce dell'uso dell'oro risalgono a migliaia di anni fa, quando le antiche civiltà lo consideravano un simbolo di ricchezza e potere. I faraoni egiziani lo utilizzavano per ornamenti e gioielli, mentre i greci e i romani lo impiegavano nelle monete e nelle opere d'arte.
          </p>
          <img src={egypt} alt="Antica moneta d'oro romana" className="w-full h-auto mb-8 opacity-75" />
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Medioevo</h2>
          <p className="mb-8 text-neutral-400">
            Durante il Medioevo, l'oro continuò a svolgere un ruolo importante come mezzo di scambio e status symbol. Le grandi civiltà europee accumulavano riserve d'oro e lo utilizzavano per finanziare le loro imprese militari e commerciali.
          </p>
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Epoca moderna</h2>
          <p className="mb-8 text-neutral-400">
            Con l'avvento delle esplorazioni globali e la scoperta di nuovi giacimenti d'oro in America e in altre parti del mondo, l'oro divenne sempre più prezioso e ambito. Durante il XIX secolo, la corsa all'oro in California e in Australia attirò migliaia di cercatori, dando vita a nuove città e comunità.
          </p>
          <img src={goldrush} alt="Miniera d'oro moderna" className="w-full h-auto mb-8 opacity-75" />
          <h2 className="text-2xl font-bold text-slate-50 mb-4">Oggi</h2>
          <p className="mb-8 text-neutral-400">
            Oggi, l'oro continua ad essere una risorsa preziosa e un investimento sicuro. Viene utilizzato in vari settori, dall'industria elettronica alla gioielleria, e continua ad essere un rifugio sicuro in tempi di incertezza economica.
          </p>
          <p className="mb-8 text-neutral-400">
            La storia dell'oro è una storia di fascino e mistero, che continua a influenzare il mondo moderno. Le sue proprietà uniche e il suo valore intrinseco lo rendono uno dei metalli più preziosi e desiderati al mondo.
          </p>
          <img src={pepita} alt="Orologio d'oro moderno" className="w-full h-auto mb-8 opacity-75" />
        </div>
      </div>
      <footer className="text-center my-8">
        <Link to="/" className="text-neutral-100 flex items-center justify-center">
          <img src={logo} alt="Logo Prezzo Oro" className="mr-2 h-8 w-8" />
          Prezzo-Oro.com
        </Link>
      </footer>
    </>
  );
}

export default Storia;
