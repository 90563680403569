import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Lottie from 'react-lottie';
import loadingAnimation from '../assets/json/loadingAnimation.json';
import lingotti from '../assets/prezzoOro/lingotti.png';
import { Helmet } from 'react-helmet-async';
import imageShare from '../assets/logo/sherelogo.png' ;
import { Link } from 'react-router-dom';
import img1 from '../assets/articles/2024/gold_bar_record.webp';
import articles2024 from '../assets/articles/2024/2024.json'; 
import logo from '../assets/logo/logo_icon.png';


function Notizie() {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    const reversedArticles = [...articles2024].reverse();
    setPostList(reversedArticles);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://newsdata.io/api/1/news?apikey=pub_423107a340d29513ed20a4f8f6419b008d51a&q=metalli%20preziosi&language=it&category=business'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch news');
        }
        const data = await response.json();
        setNews(data.results);
      } catch (error) {
        console.error('Error fetching news:', error);
        // setError('Ops, al momento non ci sono notizie disponibili. Ti invitiamo a tornare più tardi per ulteriori aggiornamenti!');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
        // Funzione di cleanup
    };

  }, []);

  return (
    <>      
      <Helmet>
        <title>Ultime Notizie sui Metalli Preziosi | prezzo-oro.com</title>
        <meta name="description" content="Scopri le ultime notizie sui mercati relativi ai metalli preziosi, inclusi aggiornamenti sui prezzi dell'oro, argento, platino e altro. Resta sempre informato sulle ultime tendenze e sviluppi nel settore dei metalli preziosi." />
        <meta name="keywords" content="ultime notizie, metalli preziosi, mercati finanziari, oro, argento, platino, palladio" />
        <meta name="author" content="prezzo-oro.com" />
        <meta property="og:title" content="Ultime Notizie sui Metalli Preziosi | prezzo-oro.com" />
        <meta property="og:description" content="Scopri le ultime notizie sui mercati relativi ai metalli preziosi, inclusi aggiornamenti sui prezzi dell'oro, argento, platino e altro. Resta sempre informato sulle ultime tendenze e sviluppi nel settore dei metalli preziosi." />
        <meta property="og:image" content={imageShare} />
        <meta property="og:url" content="https://prezzo-oro.com/ultime-notizie-metalli-preziosi" />
        <link rel="canonical" href="https://prezzo-oro.com/ultime-notizie-metalli-preziosi" />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content="2024-04-20T10:30:00" />
        <meta property="article:author" content="prezzo-oro.com" />
        <meta property="article:section" content="Ultime Notizie Metalli Preziosi" />
        <meta property="article:tag" content="ultime notizie, metalli preziosi, mercati finanziari, oro, argento, platino, palladio" />
        <meta name="google-adsense-account" content="ca-pub-7812738073531507"/>
      </Helmet>
      <Navbar/> 
      <div className='w-full text-center my-20'>
          <h1 className='uppercase text-slate-50 font-semibold text-2xl text-static-gold mb-5'>Ultime Notizie sui Metalli Preziosi</h1>  
      </div>
      <div className="container mx-auto mt-8 h-dvh">
        {loading ? (
            <Lottie 
                options={{
                    animationData: loadingAnimation,
                    loop: true,
                    autoplay: true
                }}
                height={100}
                width={100}
            />
        ) : error ? (
          <div className='md:px-56 px-5 text-center my-20'>
            <p className='uppercase text-slate-50 font-semibold text-xl text-slate-400 mb-5'>{error}</p>  
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-20 px-5 pb-36">
            {postList.map(post => (
              <div key={post.id} className="overflow-hidden mb-6">
                <img src={post.image} alt="lingotti d'oro" className="w-full h-48 object-cover object-top" />
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2 text-slate-50">{post.title}</h2>
                  <p className="text-slate-400 truncate hover:text-clip mb-6 leading-loose">{post.description} <br/> 
                  {/* <span className="text-neutral-100">{post.date}</span> */}
                  </p>
                  <Link to={`${post.link}/`} className="text-slate-200 border rounded px-4 py-3 hover:bg-slate-200 hover:text-black transition duration-300 cursor-pointer">
                    Leggi di più
                  </Link>
                </div>
              </div>
            ))}
            {news.map((item, index) => (
              <div key={index} className=" overflow-hidden">
                {item.image_url === '' ? 
                  <img src={lingotti} alt={item.title} className="w-full h-48 object-cover" />
                : 
                  <picture>
                    <source srcSet={item.image_url} onError={(e) => { e.target.srcset = lingotti; }} />
                    <img src={lingotti} alt={item.title} className="w-full h-48 object-cover" />
                  </picture>
                }
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2 text-slate-50">{item.title}</h2>
                  <p className="text-slate-400 mb-4 truncate hover:text-clip">{item.description}</p>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-slate-200 border rounded px-4 py-3 hover:bg-slate-200 hover:text-black transition duration-300 cursor-pointer">Leggi di più</a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Notizie;
